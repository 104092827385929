// import { React , useState, useEffect } from 'react';
import { React } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import AppRoutes from './Routes';

function App() {
  // const [aboutText, setAboutText] = useState('');
  
  // const imageUrls = {
  //   logo: '/images/zaia-logo.svg',
  //   lightBackground: '/images/bakgrund-light.webp',
  //   massage: '/images/massage.webp',
  //   ansikte: '/images/ansikte.webp',
  //   fransarBryn: '/images/fransar-bryn.webp',
  //   vaxning: '/images/vaxning.webp',
  //   profile: '/images/profile.webp'
  // };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       // Define query parameters
  //       const tables = [
  //         { tableName: 'about', columns: ['content'] },
  //         { tableName: 'services', columns: ['title', 'summary'] }
  //       ];
  
  //       // Construct the URL with query parameters
  //       const url = `https://zaianaturligskonhet.se/.netlify/functions/fetchData?tables=${encodeURIComponent(JSON.stringify(tables))}`;
  
  //       const response = await fetch(url);
  //       const data = await response.json();
        
  //       if (data.message) {
  //         console.log(data.message);
  //         console.log('Fetched rows:', data.data); // Log the fetched rows
  //         setAboutText(data.data.about);
  //         // Update state with fetched data for 'services' table if needed
  //       } else {
  //         console.log(data.error);
  //       }
  //     } catch (error) {
  //       console.error('Error calling serverless function:', error);
  //     }
  //   };
  
  //   fetchData();
  // }, []);

  return (
    <div className="App">
      <Router>
        {/* <AppRoutes imageUrls={imageUrls} aboutText={aboutText} /> */}
        <AppRoutes />
      </Router>
    </div>
  );
}

export default App;