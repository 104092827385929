import React, { useEffect } from "react";
// import { Route, Routes, useLocation, Link } from "react-router-dom";
import { Route, Routes, useLocation } from "react-router-dom";
// import LazyLoad from 'react-lazyload';

// import Navbar from "./components/Navbar";
import Home from "./components/Home";
// import Massage from "./components/Massage";
// import Ansiktsbehandlingar from "./components/Ansiktsbehandlingar";
// import FransarOchBryn from "./components/FransarOchBryn";
// import Vaxning from "./components/Vaxning";
// import Footer from "./components/Footer";
// import About from "./components/About"

import "./App.css";

// const tableName = `about`;
// const columnName = `content`;

// const AppRoutes = ({ imageUrls, aboutText }) => {
const AppRoutes = () => {
    const location = useLocation();
    // const [textData, setTextData] = useState(null);

    // Scroll to top when the route changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            {/* <Navbar logoUrl={imageUrls.logo}/>
            <div className="background-container">
                <LazyLoad height={200} offset={100}>
                    <img src={imageUrls.lightBackground} alt="Background" className="background-image" />
                </LazyLoad>
                <div className="overlay-content">
                    <Link to="/kontakt" className="overlay-button">Kontakta Mig</Link>
                    <div className="vertical-line"></div>
                    <a href="https://business.bokadirekt.se/bransch/massage" target="_blank" rel="noopener noreferrer" className="overlay-button">Boka tid</a>
                </div>
            </div> */}
            <div className="content-container"> 
                <Routes>
                    <Route path="/" element={<Home />} />
                    {/* <Route path="/" element={<Home imageUrls={imageUrls} />} />
                    <Route path="/massage" element={<Massage />} />
                    <Route path="/ansiktsbehandlingar" element={<Ansiktsbehandlingar />} />
                    <Route path="/fransar-och-bryn" element={<FransarOchBryn />} />
                    <Route path="/vaxning" element={<Vaxning />} />
                    <Route path="/kontakt" element={<About profileImage={imageUrls.profile} profileText={aboutText} />} /> */}
                </Routes>
            </div>
            {/* <Footer /> */}
        </>
    );
}

export default AppRoutes;
