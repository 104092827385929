import React from "react";
// import { Link } from "react-router-dom";
// import LazyLoad from 'react-lazyload';
import "./Home.css";

const Home = () => {
    return (
        <>
            <h1>Sidan är under konstruktion.</h1>
        </>
    );
}

// const Home = ({ imageUrls }) => {
//     return (
//         <>
//             <div className="text-container">
//                 <h2 className="text-title">Vad Vi Erbjuder</h2>
//                 <hr className="text-divider" />
//                 <p className="text-body">
//                     Massage, ansiktsbehandlingar, frans- och brynbehandlingar samt vaxning med ekologiska produkter erbjuds.
//                 </p>
//             </div>

//             <div className="text-container">
//                 <h2 className="text-title">Behandlingar</h2>
//                 <hr className="text-divider" />
//             </div>

//             <div className="cards-container">
//                 <div className="card">
//                     <LazyLoad height={200} offset={100}>
//                         <img src={imageUrls.massage} alt="Massage" className="card-image" />
//                     </LazyLoad>
//                     <div className="card-content">
//                         <h3 className="card-title">Massagebehandlingar</h3>
//                         <p className="card-description">
//                             Massagebehandlingar för avslappning och välbefinnande, inklusive helkropps-, rygg-, nack- och lymfmassage.
//                         </p>
//                         <Link to="/massage" className="card-button">Läs mer</Link>
//                     </div>
//                 </div>
//                 <div className="card">
//                     <LazyLoad height={200} offset={100}>
//                         <img src={imageUrls.ansikte} alt="Ansiktsbehandlingar" className="card-image" />
//                     </LazyLoad>                    
//                     <div className="card-content">
//                         <h3 className="card-title">Ansiktsbehandlingar</h3>
//                         <p className="card-description">
//                             Ansiktsbehandlingar med ekologiska produkter anpassade efter hudtyp, inklusive klassisk, Pure Cell och Vitamin C.
//                         </p>
//                         <Link to="/ansiktsbehandlingar" className="card-button">Läs mer</Link>
//                     </div>
//                 </div>
//                 <div className="card">
//                     <LazyLoad height={200} offset={100}>
//                         <img src={imageUrls.fransarBryn} alt="Fransar och Bryn" className="card-image" />
//                     </LazyLoad>                      
//                     <div className="card-content">
//                         <h3 className="card-title">Fransar och Bryn</h3>
//                         <p className="card-description">
//                             Behandlingar för fransar och bryn ger ansiktet lyft, med färgning och formning för en markerad look.
//                         </p>
//                         <Link to="/fransar-och-bryn" className="card-button">Läs mer</Link>
//                     </div>
//                 </div>
//                 <div className="card">
//                     <LazyLoad height={200} offset={100}>
//                         <img src={imageUrls.vaxning} alt="Vaxning" className="card-image" />
//                     </LazyLoad>                     
//                     <div className="card-content">
//                         <h3 className="card-title">Vaxning</h3>
//                         <p className="card-description">
//                             Vaxning ger effektiv hårborttagning. Halvbenvaxning är snabb, medan helbenvaxning ger slät hud.
//                         </p>
//                         <Link to="/vaxning" className="card-button">Läs mer</Link>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }

export default Home;